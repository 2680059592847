
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/arrangement",
      function () {
        return require("private-next-pages/arrangement/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/arrangement"])
      });
    }
  